<template>
  <asom-card>
    <div v-if="isLoadingProfile" class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <form v-else class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
      <asom-alert class="col-span-2" v-if="error" variant="error" :error-message="error" />
      <asom-form-field label="SAP ID" :state="inputStates('personNo')" error="SAP ID is required">
        <asom-input-text v-model="personNo" :state="inputStates('personNo')" placeholder="Enter valid SAP ID" disabled
          style="background-color: #ddd;opacity: 0.6;" />
      </asom-form-field>
      <asom-form-field label="AD Username" :state="inputStates('username')" error="AD Username is required">
        <asom-input-text v-model="username" :state="inputStates('username')" placeholder="Enter valid username" disabled
          style="background-color: #ddd;opacity: 0.6;" />
      </asom-form-field>
      <asom-form-field label="MRT Line" required :state="inputStates('lineId')" error="MRT Line is required">
        <asom-input-select :objectModelValue="false" v-model="lineId" :options="lineOptions" placeholder="Select MRT line"
          :state="inputStates('lineId')" />
      </asom-form-field>
      <asom-form-field label="MRT Station" required :state="inputStates('stationId')" error="MRT Station is required">
        <asom-input-select :objectModelValue="false" v-model="stationId" :options="stationOptions"
          placeholder="Select MRT station" :state="inputStates('stationId')" />
      </asom-form-field>
      <asom-form-field label="DateEffectiveFrom" required :state="inputStates('dateEffectiveFrom')"
        error="DateEffectiveFrom is required">
        <asom-input-date v-model="dateEffectiveFrom" />
      </asom-form-field>
      <div class="col-span-2 flex justify-end space-x-4">
        <asom-button text="Create" @click="onSubmit" :loading="isSubmitting" />
        <asom-button text="Cancel" variant="secondary" @click="$router.push({ name: 'userStations' })" />
      </div>
    </form>
  </asom-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from '@vuelidate/validators'
import { addUserDetail, addUserEdit } from '../../services/user.service'
import inputStates from '@/mixins/inputStates'
import { useVuelidate } from '@vuelidate/core'
import { parseDateTime } from "@/helpers/dateTimeHelpers";

export default {
  name: 'EditUserDetails',
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      userStationQueueId: '',
      personNo: "",
      username: "",
      lineId: '',
      stationId: '',
      dateEffectiveFrom: null,
      isLoadingProfile: false,
      isSubmitting: false,
      error: '',
    }
  },
  validations() {
    return {
      lineId: { required },
      stationId: { required },
      dateEffectiveFrom: { required }
    }
  },
  mounted() {
    if (this.v$) {
      this.$nextTick(() => {
        this.personNo = this.officer.personNo;
        this.username = this.officer.name;
        let userStationQueueId = this.$route.params.userStationQueueId
        this.details(userStationQueueId);
      })
      this.v$.$reset()
    }
  },
  computed: {
    officer() {
      let user = this.$store.state.display.userStation;
      user.id ? sessionStorage.setItem('officer', JSON.stringify(user)) :
        user = JSON.parse(sessionStorage.getItem('officer'));
      return user
    },
    ...mapGetters({
      lineOptions: 'smrtResource/lineOptions',
    }),
    stationOptions() {
      if (!this.lineId)
        return this.$store.getters['smrtResource/stationOptions']
      else
        return this.$store.getters['smrtResource/stationOptionsByLineId'](this.lineId)
    },
  },
  methods: {
    details(id) {
      addUserDetail(id).then(res => {
        if (res.success) {
          let data = res.payload.data;
          this.userStationQueueId = data.userStationQueueId;
          this.lineId = data.lineId;
          this.stationId = data.stationId;
          this.dateEffectiveFrom = data.dateEffectiveFrom;
        }
      })
    },
    async onSubmit() {
      if (this.isSubmitting) return;
      this.error = ''
      this.v$.$reset()
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        addUserEdit({
          userStationQueueId: this.userStationQueueId,
          lineId: this.lineId,
          stationId: this.stationId,
          dateEffectiveFrom: parseDateTime(this.dateEffectiveFrom, false)
        }).then(res => {
          this.isSubmitting = false
          if (res.success)
            this.$router.push({ name: 'userStations' })
          else
            this.error = res.payload
        })
      } else {
        this.error = 'Please complete all required fields'
      }
    },
  }
}
</script>